import React from 'react';
import { Box } from '@mui/material';
import { StyledContent, StyledHeading, StyledImage, StyledNotification } from './Notification.styles';

export interface NotificationProps {
  iconPath: string;
  heading: string;
  children: React.ReactNode;
}

const Notification = ({ iconPath, heading, children }: NotificationProps) => (
  <StyledNotification>
    <StyledImage src={iconPath} aria-hidden />
    <Box>
      <StyledHeading variant="h3">{heading}</StyledHeading>
      <StyledContent>{children}</StyledContent>
    </Box>
  </StyledNotification>
);

export default Notification;
