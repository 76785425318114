import React from 'react';

const remoteUrlsCache = new Set();

const useDynamicScript = (remoteUrl: string) => {
  const [isModuleReady, setIsModuleReady] = React.useState(false);
  const [hasModuleError, setHasModuleError] = React.useState(false);

  React.useEffect(() => {
    if (!remoteUrl) return;

    if (remoteUrlsCache.has(remoteUrl)) {
      setIsModuleReady(true);
      setHasModuleError(false);
      return;
    }

    setIsModuleReady(false);
    setHasModuleError(false);

    const element = document.createElement('script');

    element.src = remoteUrl;
    element.type = 'text/javascript';
    element.async = true;

    element.onload = () => {
      remoteUrlsCache.add(remoteUrl);
      setIsModuleReady(true);
    };

    element.onerror = () => {
      console.error(`Dynamic Module Error: ${remoteUrl}`);
      setIsModuleReady(false);
      setHasModuleError(true);
    };

    document.head.appendChild(element);

    return () => {
      remoteUrlsCache.delete(remoteUrl);
      document.head.removeChild(element);
    };
  }, [remoteUrl]);

  return {
    hasModuleError,
    isModuleReady,
  };
};

export default useDynamicScript;
