import { HighBondUrlParts, getHighBondUrlParts } from '@acl-services/highbond-common';
import Environment from './environment';
import FeatureToggles, { flippers } from './featureToggles';
import PlatformApis from './platformApis';
import Constants from './constants/constants';

const urlParts: HighBondUrlParts = getHighBondUrlParts(window.location.origin);

const environment = new Environment(urlParts);
const platformApis = new PlatformApis().getApis(urlParts);
const featureToggles = new FeatureToggles(flippers, environment).load();
const constants = new Constants();

const AppConfig = {
  environment,
  featureToggles,
  ...platformApis,
  constants,
};

export default AppConfig;
