import App from './App';
import React from 'react';
import ReactDOM from 'react-dom/client';
import * as target from '../target.json';

if (process.env.NODE_ENV === 'development') {
  login().then(() => {
    renderApp();
  });
}

if (process.env.NODE_ENV === 'production') {
  renderApp();
}

async function login() {
  try {
    await fetch(`/login?redirect_uri=${encodeURIComponent('http://localhost:3000')}/`, { mode: 'no-cors' })
      .then((res) => res.text())
      .then(async (res) => {
        let csrfParamMatch = res.match(/<meta.*name="csrf-param".*content="(.*)".*\/>/);
        let csrfValueMatch = res.match(/<meta.*name="csrf-token".*content="(.*)".*\/>/);

        let isLoginPage = res.match(/<form[^>]+action="\/login"/m);

        if (isLoginPage) {
          const data = new URLSearchParams();
          data.append('user[email]', target.userName);
          data.append('user[password]', target.password);
          if (csrfValueMatch && csrfParamMatch) {
            data.append(csrfParamMatch[1], csrfValueMatch[1]);
          }

          const options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: data,
          };

          await fetch('/login', options)
            .then((res) => res.text())
            .then((res) => {
              if (res && res.indexOf('Invalid email or password') > 0) {
                throw 'Could not login. Check username and password in target.json';
              }
            })
            .catch((er) => {
              if (er.status !== -1) {
                console.log(JSON.stringify(er));
                throw 'Could not log in due to following error.' + er;
              }
            });
        }
      })
      .catch((er) => {
        console.log(JSON.stringify(er));
        throw 'Could not load log in page due to following error.' + er;
      });
  } catch (er) {
    console.log('login failed', er);
  }
}

function renderApp() {
  ReactDOM.createRoot(document.getElementById('root')!).render(<App />);
}
