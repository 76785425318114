import React, { FC, ReactElement } from 'react';
import { Button, Box, Grid, useTheme } from '@mui/material';
import { BoxContainer, EnvironmentVisualIndicatorContainer } from './GlobalFooter.style';
import { useTranslation } from 'react-i18next';
import AppConfig from '../../appConfig';
import ExternalLink from '@diligentcorp/atlas-react-icons/dist/esm/lens/ExternalLink.js';

export const GlobalFooter: FC = (): ReactElement => {
  const { t } = useTranslation();
  const { tokens } = useTheme();

  function getFooterContent(): React.ReactNode {
    return (
      <>
        <Button
          rel="noopener noreferrer"
          data-testid="link-terms"
          href={t('terms_of_use_url')}
          target="_blank"
          endIcon={<ExternalLink />}
        >
          {t('terms_of_service')}
        </Button>
        <Box sx={{ padding: `0 ${tokens.semantic.spacing.xs.value}` }} component={'span'}>
          {'|'}
        </Box>
        <Button
          data-testid="link-policy"
          rel="noopener noreferrer"
          target="_blank"
          href={t('privacy_policy_url')}
          endIcon={<ExternalLink />}
        >
          {t('privacy_policy')}
        </Button>
      </>
    );
  }

  return (
    <BoxContainer data-testid="footer-container">
      <Grid container direction="column" alignItems="center">
        <Grid item xs={12}>
          {AppConfig.environment.isGovRegion && (
            <>
              <EnvironmentVisualIndicatorContainer>
                {AppConfig.environment.isSled && 'Fedramp Moderate'}
                {AppConfig.environment.isGov && 'Fedramp  Il5'}
                {AppConfig.environment.isDod && 'DoD Environment'}
              </EnvironmentVisualIndicatorContainer>
              <Box sx={{ padding: `0 ${tokens.semantic.spacing.default.value}` }} component={'span'}>
                {' '}
              </Box>
            </>
          )}
          {getFooterContent()}
        </Grid>
      </Grid>
    </BoxContainer>
  );
};

export default GlobalFooter;
