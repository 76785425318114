import { Box, styled, useTheme } from '@mui/material';

interface SortableContainerProps {
  isDragging: boolean;
}

export const SortableContainer = styled(Box)<SortableContainerProps>(({ isDragging }) => {
  const { tokens } = useTheme();
  const { semantic } = tokens;
  return {
    margin: `0 0 ${tokens.core.spacing['1_5'].value} 0`,
    boxShadow: isDragging
      ? `0px 10px 32px -6px #00000052, 0px 4px 4px -2px #00000014, ${semantic.color.ui.focusRing.value} 0px 0px 0px 3px`
      : 'none',
    borderRadius: tokens.core.spacing['1_5'].value,
  };
});
