import React, { useEffect, useState } from 'react';
import { IconSize, SectionKey } from '@diligentcorp/atlas-web-component-global-nav/lib/configuration/types';
import { APP_SECTIONS } from '@diligentcorp/atlas-web-component-global-nav/lib/configuration/constants';
import '@diligentcorp/atlas-web-component-icons/lib/resources/index.js';

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'dil-resources-icon': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
    }
  }
}

interface AppSectionIconProps {
  sectionKey: SectionKey | null;
  size?: IconSize;
}

const AppSectionIcon: React.FC<AppSectionIconProps> = ({ sectionKey, size = 'lg' }) => {
  const [tagName, setTagName] = useState<string | null>(null);

  useEffect(() => {
    if (sectionKey) {
      const appSection = Object.values(APP_SECTIONS).find((section) => section.key === sectionKey);

      if (appSection) {
        appSection.loadIcon().then(() => {
          const templateResult = appSection.icon();

          // Extract the tag name from `templateResult.strings`
          const tagMatch = templateResult.strings[0].match(/<([a-zA-Z0-9-]+)/);
          if (tagMatch) {
            setTagName(tagMatch[1]); // e.g., 'dil-audit-analytics-icon'
          }
        });
      }
    }
  }, [sectionKey, size]);

  return (sectionKey && (sectionKey as string)) === 'resources' ? (
    <dil-resources-icon />
  ) : tagName ? (
    React.createElement(tagName, { size })
  ) : null;
};

export default AppSectionIcon;
