import { Box, Typography, styled, useTheme } from '@mui/material';

export const StyledNotification = styled(Box)(() => {
  const { tokens } = useTheme();
  const { semantic } = tokens;

  return {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    gap: semantic.spacing['2xl'].value,
    height: '380px',
    justifyContent: 'center',
    textAlign: 'center',
  };
});

export const StyledImage = styled('img')`
  width: 124px;
`;

export const StyledHeading = styled(Typography)(() => {
  const { tokens } = useTheme();
  const { semantic } = tokens;

  return {
    fontSize: semantic.fontSize.text.body.value,
    fontWeight: semantic.fontWeight.emphasis.value,
    marginBottom: semantic.spacing.lg.value,
    textTransform: 'uppercase',
  };
});

export const StyledContent = styled(Box)(() => {
  const { tokens } = useTheme();
  const { semantic } = tokens;

  return {
    color: semantic.color.text.muted.value,
    p: {
      margin: 0,
    },
  };
});
