import AppConfig from '../../../appConfig';
import http from '../../http';
export default async function getUserPreferences() {
  const response = await http.get(`${AppConfig.userPreferences}`);
  if (response.ok) {
    return await response.json();
  } else {
    throw new Error(response.statusText);
  }
}
