import React, { useState } from 'react';
import { Alert, AlertTitle, Button, Typography } from '@mui/material';
import sanitizeHtml from 'sanitize-html';
import { useTranslation } from 'react-i18next';

const StatusMessage = ({ statusMessage }: { statusMessage: string }) => {
  const [open, setOpen] = useState(true);
  const { t } = useTranslation();

  interface statusMessageDetails {
    severity: 'success' | 'info' | 'warning' | 'error' | undefined;
    alertTitle: string;
  }

  const statusMessageDetails: statusMessageDetails = {
    //set message detail severity and alert title based on the subscription message
    severity: 'error',
    alertTitle: t('error_status_message.subscription_expired_title'),
  };

  const DismissButton = ({
    dismissClicked = () => {
      setOpen(false);
    },
  }) => (
    <Button variant="text" size="medium" role="Dismiss" onClick={dismissClicked}>
      Dismiss
    </Button>
  );

  //render dismiss button for success, info and warning messages only
  function getActionButton(severity: 'success' | 'info' | 'warning' | 'error' | undefined) {
    if (severity === 'success' || severity === 'info' || severity === 'warning')
      return <DismissButton dismissClicked={() => setOpen(false)} />;
  }

  if (statusMessage == null || statusMessage == '') {
    return null;
  }

  return (
    <>
      {open && statusMessage && statusMessage != '' && (
        <Alert
          data-testid="status-message"
          severity={statusMessageDetails.severity}
          action={getActionButton(statusMessageDetails.severity)}
          variant="standard"
          aria-live="polite"
        >
          <AlertTitle>{statusMessageDetails.alertTitle}</AlertTitle>
          <Typography dangerouslySetInnerHTML={{ __html: sanitizeHtml(statusMessage) }}></Typography>
        </Alert>
      )}
    </>
  );
};

export default StatusMessage;
