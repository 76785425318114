// Class which exports all the global variables used in the application

export default class constants {
  // get conset cookie name
  get consentCookie(): string {
    return 'highbond_secure_environment_consent';
  }
  get mobileBreakpoint(): number {
    return 600;
  }
}
