import { Typography, styled, useTheme, Box, IconButton } from '@mui/material';

export const StyledDemoFooterText = styled(Typography)(() => {
  const { tokens } = useTheme();
  return {
    fontWeight: tokens.semantic.fontWeight.emphasis.value,
  };
});

export const FavoriteSecHeadingContainer = styled(Box)(() => {
  const { tokens } = useTheme();
  return {
    padding: `${tokens.core.spacing['1'].value} 0px`,
  };
});
export const FavoriteSecHeadingDetails = styled(Box)(() => {
  const { tokens } = useTheme();
  return {
    display: 'flex',
    alignItems: 'center',
    gap: tokens.core.spacing['2'].value,
    flex: '1 0 0',
    alignSelf: 'stretch',
  };
});

export const FavoriteSubHeading = styled(Typography)(() => {
  const { tokens } = useTheme();
  return {
    fontSize: tokens.core.spacing['1_5'].value,
  };
});

export const FavoriteSecHeading = styled(Typography)(() => {
  const { tokens } = useTheme();
  const { semantic } = tokens;

  return {
    fontWeight: semantic.fontWeight.emphasis.value,
  };
});

export const FavoriteStarIcon = styled(IconButton)(() => {
  const { tokens } = useTheme();

  return {
    width: 'unset',
    padding: 0,
    height: tokens.core.spacing['3'].value,
  };
});

export const FavoritesCardsContainer = styled(Box)(() => {
  const { tokens } = useTheme();
  return {
    marginTop: tokens.core.spacing['1_5'].value,
  };
});
