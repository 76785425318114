export default function addScript(url: string, async = false, defer = false, shouldSkipInDev = true) {
  if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test') {
    if (shouldSkipInDev) return;
  }

  const scriptTag = document.createElement('script');
  scriptTag.type = 'text/javascript';
  scriptTag.crossOrigin = 'use-credentials';
  scriptTag.src = url;
  scriptTag.async = async; // async - Load's the script asynchronously without blocking the Html parsing and ensures that the script is executed as soon as it is downloaded.
  scriptTag.defer = defer; // defer - Load's the script asynchronously without blocking the Html parsing and ensures that the script is executed after the document has been parsed.
  document.head.appendChild(scriptTag);
}
