import { QueryClient } from '@tanstack/react-query';

const SIXTY_SECONDS = 60 * 1000;
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: SIXTY_SECONDS,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
    },
  },
});

export default queryClient;
