import React, { FC, ReactElement } from 'react';
import type { LensTheme } from '@diligentcorp/atlas-theme-mui/lib/themes/lens/types';
import { Box, Button, Drawer, useTheme } from '@mui/material';
import { getContentUrl } from '../../helpers/common';
import { StyledDemoHeaderText, StyledDemoFooterText } from './DemoContent.style';
interface PopupDialogProps {
  open: boolean;
  onClose: () => void;
  title: string;
  appKey?: string;
}

function renderDemoContent(appKey: string | undefined, title: string) {
  return (
    <>
      <StyledDemoHeaderText variant="h2">{title}</StyledDemoHeaderText>

      <Box width="100%">
        <iframe
          width="100%"
          frameBorder="0"
          allow="fullscreen https://play.vidyard.com/"
          height="234px"
          data-testid="demo-video"
          src={getContentUrl(appKey)}
        />
      </Box>

      <StyledDemoFooterText variant="subtitle1">
        Let us know and we'll contact you shortly to let you try it out.
      </StyledDemoFooterText>
    </>
  ); // content will be loaded baseed on app key
}
const DemoContent: FC<PopupDialogProps> = ({ open, onClose, title, appKey }): ReactElement => {
  const theme = useTheme<LensTheme>();
  const SideSheetPresets = theme.presets.SideSheetPersets;
  const { size, components } = SideSheetPresets;
  const { Header, Footer } = components;
  const renderDemoComponent = (
    <>
      <Header
        onClose={onClose}
        titleText="Try the Diligent One Platform"
        closeButtonProps={{ 'aria-label': 'Close side sheet', 'aria-controls': 'sideSheetId' }}
      />
      <>{renderDemoContent(appKey, title)}</>
      <Footer>
        <Button onClick={onClose} variant="outlined">
          Close
        </Button>
      </Footer>
    </>
  );
  return (
    <Drawer open={open} onClose={onClose} sx={{ ...size['medium'].sx }}>
      {renderDemoComponent}
    </Drawer>
  );
};

export default DemoContent;
