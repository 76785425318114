import { Box, styled, useTheme } from '@mui/material';

export const BoxContainer = styled(Box)(() => {
  const { tokens } = useTheme();
  return {
    width: '100%',
    height: 'auto',
    padding: `${tokens.semantic.spacing.lg.value} 0`,
    color: tokens.semantic.color.text.muted.value,
    fontSize: tokens.semantic.fontSize.text.sm.value,
  };
});

export const EnvironmentVisualIndicatorContainer = styled(Box)(() => {
  const { tokens } = useTheme();
  return {
    display: 'inline',
    cursor: 'default',
    borderRadius: tokens.semantic.radius.md.value,
    textTransform: 'uppercase',
    padding: `${tokens.semantic.spacing.sm.value} ${tokens.semantic.spacing.default.value}`,
    backgroundColor: tokens.semantic.color.link.default.value,
    color: tokens.semantic.color.text.inverse.value,
    fontSize: tokens.semantic.fontSize.text.sm.value,
  };
});
