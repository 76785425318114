import { de } from './de.yml';
import { en } from './en.yml';
import { es } from './es.yml';
import { fr } from './fr.yml';
import { ja } from './ja.yml';
import { pt } from './pt.yml';
import { zh } from './zh.yml';
import { nl } from './nl.yml';
import { it } from './it.yml';

const locales = { de, en, es, fr, ja, pt, zh, nl, it };

export const FALLBACK_LNG = 'en';

export default locales;
