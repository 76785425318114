import { Typography, styled, useTheme, Box } from '@mui/material';

export const AppSecHeadingContainer = styled(Box)(() => {
  const { tokens } = useTheme();
  return {
    padding: `${tokens.core.spacing['1'].value} 0px`,
  };
});

export const AppSecHeading = styled(Typography)(() => {
  const { tokens } = useTheme();
  const { semantic } = tokens;

  return {
    fontWeight: semantic.fontWeight.emphasis.value,
  };
});

export const SectionCardsContainer = styled(Box)(() => {
  const { tokens } = useTheme();

  return {
    marginTop: tokens.core.spacing['1_5'].value,
  };
});
