import AppConfig from '../../../appConfig';
import http from '../../http';
import { Session } from '../../../types/Session';

export default async function getSession(): Promise<Session | undefined> {
  // TODO: We should use the apiEndpoint to fetch a valid session from the backend service of the Landing Page service.
  const response = await http.get(`${AppConfig.authyApi}/session`);

  if (response.ok) {
    return await response.json();
  } else {
    throw new Error(response.statusText);
  }
}
