import AppConfig from '../../../appConfig';
import http from '../../http';
export default async function updateUserPreferences(userPreferencePayload: any) {
  const response = await http.post(`${AppConfig.userPreferences}`, JSON.stringify({ ...userPreferencePayload }));
  if (response.ok) {
    return await response.json();
  } else {
    throw new Error(response.statusText);
  }
}
