import React from 'react';
import FavoriteCard from '../FavoriteCard';
import { replaceKey } from '../../helpers/common';
import { FavoriteAppData, FavoriteCardComponentType } from '../../../types';
import { AccordionSummary, Box } from '@mui/material';
import { StyledAccordion, StyledSectionHeader, AccordionDetailsContainer } from './FavoriteApps.style';
import { useTranslation } from 'react-i18next';
import { CombinedData } from '../../hooks/queries/useCombinedGlobalNavData';
import { AppKey } from '@diligentcorp/atlas-web-component-global-nav/lib/configuration/types';

//this mock data has to come from API or some other source
interface FavoriteAppsProps {
  data: CombinedData;
  sortableApps: FavoriteAppData[];
  handleFavoriteButtonClicked: (appData: FavoriteAppData) => void;
}

const FavoriteApps: React.FC<FavoriteAppsProps> = ({ data, sortableApps, handleFavoriteButtonClicked }) => {
  const { processedData } = data;
  const { t } = useTranslation();

  function transfermAppData(app: any, sectionKey: string): FavoriteAppData {
    return {
      appName: app.title,
      appKey: app.key,
      sectionKey: sectionKey,
      appLink: app.url,
    };
  }
  const isFavourite = (appKey: AppKey): boolean => {
    return sortableApps.find((app: FavoriteAppData) => app.appKey == appKey) ? true : false;
  };

  return (
    <>
      {processedData.map(({ apps, key: sectionKey }) => (
        <StyledAccordion defaultExpanded key={`panel-${sectionKey}`}>
          <AccordionSummary aria-controls={`${sectionKey}-content`} id={`${sectionKey}-header`}>
            <StyledSectionHeader
              dangerouslySetInnerHTML={{ __html: t(`sections.${replaceKey(sectionKey)}`) }}
            ></StyledSectionHeader>
          </AccordionSummary>
          <AccordionDetailsContainer id={`${sectionKey}-content`}>
            {apps.map((app: any) => (
              <Box data-testid="favorite-app-selector-list">
                <FavoriteCard
                  key={app.key}
                  favoriteAppData={transfermAppData(app, sectionKey)}
                  onFavoriteButtonClick={(value: FavoriteAppData) => handleFavoriteButtonClicked(value)}
                  componentType={FavoriteCardComponentType.View}
                  isFavorite={isFavourite(app.key)}
                />
              </Box>
            ))}
          </AccordionDetailsContainer>
        </StyledAccordion>
      ))}
    </>
  );
};
export default FavoriteApps;
