import { Typography, styled, useTheme } from '@mui/material';

export const StyledDemoHeaderText = styled(Typography)(() => {
  const { tokens } = useTheme();
  return {
    fontWeight: tokens.semantic.fontWeight.emphasis.value,
  };
});

export const StyledDemoFooterText = styled(Typography)(() => {
  const { tokens } = useTheme();
  return {
    fontWeight: tokens.semantic.fontWeight.emphasis.value,
  };
});
