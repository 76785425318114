import React, { ReactElement } from 'react';
import { EmptyCardsSection, SkeletonSectionSubTitle } from './FavoriteEmptySection.style';
import FavoriteCard from '../FavoriteCard';
import { FavoriteAppData, FavoriteCardComponentType } from '../../../types';

interface FavoriteEmptySectionProps {
  cardCount?: number;
  headerSubtitle?: string;
  footerSubtitle?: string;
}
const FavoriteEmptySection = ({
  cardCount = 1,
  headerSubtitle,
  footerSubtitle,
}: FavoriteEmptySectionProps): ReactElement => {
  function handleFavoriteClick(): void {
    return;
  }

  const emptyFavoriteAppProps: FavoriteAppData = {
    appKey: 'activity-center',
    appName: 'empty app',
    sectionName: 'empty section',
    appLink: '#',
  };

  function isEmptyString(str: string | null | undefined): boolean {
    return str === '' || str === null || str === undefined;
  }

  return (
    <>
      {!isEmptyString(headerSubtitle) && <SkeletonSectionSubTitle>{headerSubtitle}</SkeletonSectionSubTitle>}
      <EmptyCardsSection>
        {Array.from({ length: cardCount }).map((_, index) => (
          <FavoriteCard
            key={index}
            favoriteAppData={emptyFavoriteAppProps}
            onFavoriteButtonClick={handleFavoriteClick}
            componentType={FavoriteCardComponentType.Skeleton}
          />
        ))}
      </EmptyCardsSection>
      {!isEmptyString(footerSubtitle) && <SkeletonSectionSubTitle>{footerSubtitle}</SkeletonSectionSubTitle>}
    </>
  );
};

export default FavoriteEmptySection;
