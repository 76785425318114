import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  CardContent,
  CardContentProps,
  Typography,
  styled,
  useTheme,
} from '@mui/material';

export const AppName = styled(Typography)(() => {
  const { tokens } = useTheme();
  const { semantic } = tokens;

  return {
    color: semantic.color.action.default.value,
    display: '-webkit-box',
    fontWeight: semantic.fontWeight.emphasis.value,
    fontSize: semantic.fontSize.text.md.value,
    lineHeight: semantic.lineHeight.text.md.value,
    overflow: 'hidden',
    textAlign: 'center',
    textOverflow: 'ellipsis',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: '2',
  };
});

export const AppNameWrapper = styled(Box)`
  align-items: center;
  display: flex;
  justify-content: center;
`;

export const SpinnerWrapper = styled(Box)(() => {
  const { tokens } = useTheme();
  const { semantic } = tokens;

  return {
    alignItems: 'center',
    border: `1px solid ${semantic.color.action.disabled.value}`,
    borderRadius: semantic.radius.md.value,
    display: 'flex',
    height: '600px',
    justifyContent: 'center',
    width: '100%',
  };
});

export const StyledCard = styled(Card)(() => {
  const { tokens } = useTheme();
  const { semantic } = tokens;

  return {
    alignItems: 'center',
    border: `1px solid ${semantic.color.action.onDark.default.value}`,
    boxShadow: 'none',
    flexShrink: 0,
    height: '98px',
    padding: semantic.spacing.md.value,
    width: '196px',
    '&:focus-within': {
      boxShadow: semantic.shadow.focus.value.stringValue,
    },
    '&:hover': {
      backgroundColor: semantic.color.action.disabled.value,
      borderColor: semantic.color.action.onDark.hover.value,
      '& p': {
        color: semantic.color.action.hover.value,
      },
    },
  };
});

export const StyledCardContent = styled(CardContent)<CardContentProps<'a', { component: 'a' }>>`
  align-items: center;
  color: inherit;
  gap: 0;
  height: 100%;
  text-decoration: none;
  width: 100%;
  justify-content: space-evenly;
  &:focus-visible {
    outline: none;
  }
`;

export const StyledAccordion = styled(Accordion)`
  background-color: transparent;
  border: 0;
  width: 100% !important; // style is important to override side bar styles
`;

export const StyledAccordionSummary = styled(AccordionSummary)(() => {
  const { tokens } = useTheme();
  const { semantic } = tokens;

  return {
    backgroundColor: 'transparent',
    '& .MuiAccordionSummary-content': {
      fontWeight: semantic.fontWeight.emphasis.value,
    },
  };
});

export const StyledSectionTitle = styled(Typography)(() => {
  return {
    '& span': {
      color: '#000 !important', // Set your desired background color here
    },
  };
});
export const StyledAccordionDetails = styled(AccordionDetails)(() => {
  const { tokens } = useTheme();
  const { semantic } = tokens;

  return {
    display: 'flex',
    flexWrap: 'wrap',
    gap: semantic.spacing.lg.value,
    paddingBottom: semantic.spacing['2xl'].value,
  };
});

export const AccordionDetailsContainer = styled(AccordionDetails)(() => {
  const { tokens } = useTheme();

  return {
    padding: '0',
    display: 'grid',
    rowGap: tokens.core.spacing['1_5'].value,
    margin: `${tokens.core.spacing['1_5'].value} 0`,
  };
});

export const StyledSectionHeader = styled(Box)(() => {
  const { tokens } = useTheme();
  const { semantic } = tokens;
  return {
    fontWeight: semantic.fontWeight.emphasis.value,
  };
});
