import { useQuery, UseQueryResult } from '@tanstack/react-query';
import * as api from '../../api';
import { GlobalNav } from '../../types/GlobalNavData';

export function useGlobalNavData(
  org_id: string | undefined,
  user_id: string | undefined,
  excludeTransformation = false,
): UseQueryResult<GlobalNav | undefined> {
  return useQuery(['globalNavData', org_id, user_id], () => api.getGlobalNavData(org_id, user_id), {
    select: (data) => {
      if (excludeTransformation) {
        return data?.data;
      } else {
        return api.navDataTransformation(data, org_id!).data;
      }
    },
    cacheTime: Infinity,
    staleTime: Infinity,
    enabled: !!org_id,
  });
}
