import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Alert, AlertTitle } from '@mui/material';
import Cookies from 'universal-cookie';
import AppConfig from '../../appConfig';

interface ModalType {
  Title: string | JSX.Element;
  Content: string | JSX.Element;
  Actions: ({ onClose }: { onClose: () => void }) => JSX.Element;
}

const DodModel: ModalType = {
  Title: <> HighBond admin system use notification </>,
  Content: (
    <Alert severity="warning" variant="outlined">
      <AlertTitle> Note: By continuing to use this system you are acknowledging this notification.</AlertTitle>
      1. Users are accessing a U.S. Government system
      <br /> 2. System usage may be monitored, recorded, and subject to audit
      <br />
      3. Unauthorized use of the system is prohibited and subject to criminal and civil penalties
      <br /> 4. Use of the information system indicates consent to monitoring and recording
      <br />
    </Alert>
  ),
  Actions: ({ onClose }) => (
    <>
      <Button variant="contained" onClick={onClose} autoFocus>
        I Accept
      </Button>
    </>
  ),
};

// Function to save the user's acceptance of the DOD notification in session storage
const saveDodAcceptance = () => {
  const CONSENT_COOKIE = AppConfig.constants.consentCookie;
  const ALL_PAGES = '/';
  const DOMAIN_REGEX = /\.(highbond|diligentoneplatform)((?:-\w+)+)?\.(local|com|mil)/;
  const domain = window.location.origin.match(DOMAIN_REGEX)?.shift() || '';
  const cookies = new Cookies();

  if (!cookies.get(CONSENT_COOKIE)) {
    cookies.set(CONSENT_COOKIE, true, { domain, path: ALL_PAGES });
  }
};

const DodAcceptancePopUp = () => {
  const [open, setOpen] = useState(true);

  const handleOnClose = () => {
    setOpen(false);
    saveDodAcceptance();
  };

  const { Title, Content, Actions } = DodModel;
  return (
    <Dialog
      aria-describedby="alert-dialog-description"
      aria-labelledby="alert-dialog-title"
      fullScreen={false}
      open={open}
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '600px', // Set your width here
          },
        },
      }}
    >
      <DialogTitle data-testid="alert-dialog-title">{Title}</DialogTitle>
      <DialogContent id="alert-dialog-description" data-testid="alert-dialog-description">
        {Content}
      </DialogContent>
      <DialogActions>
        <Actions onClose={handleOnClose} />
      </DialogActions>
    </Dialog>
  );
};

export default DodAcceptancePopUp;
