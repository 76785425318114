import Environment from '../environment';
import { FlippersType } from './flippers';

enum EnvironmentType {
  Playground = 'playground',
  Staging = 'staging',
  Preprod = 'preprod',
  Production = 'production',
}

export default class FeatureToggles {
  constructor(private _flippers: FlippersType, private _environment: Environment) {}

  private getEnvironment = (): EnvironmentType => {
    if (this._environment.isPlayground || this._environment.isLocal) {
      return EnvironmentType.Playground;
    }

    if (this._environment.isStaging) {
      return EnvironmentType.Staging;
    }

    if (this._environment.isPreprod) {
      return EnvironmentType.Preprod;
    }

    return EnvironmentType.Production;
  };

  load(): Record<string, boolean> {
    const env = this.getEnvironment();

    switch (env) {
      case EnvironmentType.Production:
        return this._flippers.production;
      case EnvironmentType.Preprod:
        return this._flippers.preprod;
      case EnvironmentType.Staging:
        return this._flippers.staging;
      case EnvironmentType.Playground:
      default:
        return this._flippers.playground;
    }
  }
}
