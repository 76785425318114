import React from 'react';
import { useTranslation } from 'react-i18next';
import { AppSecHeadingContainer, AppSecHeading, SectionCardsContainer } from './SortableSections.style';
import { SectionData } from '../../../types';
import Sortable from '../DragAndDropSorter';
import SectionCard from '../SectionCard';

interface SortableSectionsProps {
  sortableSections: SectionData[];
  handleSortableSections: (sortApps: SectionData[]) => void;
}

function reorder(sortableSections: SectionData[], source: number, destination: number) {
  const newSections = [...sortableSections];
  const [removed] = newSections.splice(source, 1);
  newSections.splice(destination, 0, removed);
  return newSections;
}

const SortableSections = ({ sortableSections, handleSortableSections }: SortableSectionsProps) => {
  const { t } = useTranslation();
  const isSortableSectionsExist = sortableSections.length > 0;

  function sortChangeHandler(source: number, destination: number | null): void {
    if (destination !== null) {
      const newSections = reorder(sortableSections, source, destination);
      handleSortableSections(newSections);
    }
  }

  function renderDraggableItems() {
    return sortableSections.map((section: SectionData, index: number) => {
      return (
        <Sortable.sortableElement key={index} sortID={index}>
          <SectionCard key={section.sectionKey} sectionData={section} />
        </Sortable.sortableElement>
      );
    });
  }

  return (
    <>
      <AppSecHeadingContainer>
        <AppSecHeading variant="h4">{t('app_Sections.sidebar_title')}</AppSecHeading>
      </AppSecHeadingContainer>
      <SectionCardsContainer>
        {isSortableSectionsExist && (
          <Sortable onChange={sortChangeHandler} data-testid="sortable-sections">
            {' '}
            {renderDraggableItems()}
          </Sortable>
        )}
      </SectionCardsContainer>
    </>
  );
};

export default SortableSections;
