import React, { ReactElement, createContext, useContext, useEffect } from 'react';
import { FavoriteAppData } from '../../../types';
import {
  StyledSectionTitle,
  FavoritesAppContainer,
  FavoriteSectionHeader,
  FavoriteSectionHeaderCols,
} from './FavoriteAppSection.styles';
import { useTranslation } from 'react-i18next';
import PaginationSection from '../PaginationSection';
import FavoriteEmptySection from '../FavoriteEmptySection';
import { useUserPreference } from '../../hooks/queries/useUserPreferences';
import FavoriteAppMenu from '../FavoriteAppMenu/FavoriteAppMenu';
import { CombinedData } from '../../hooks/queries/useCombinedGlobalNavData';
import SnackBarToast from '../SnackBarToast';
import { formatFavoritesData } from '../../helpers/common';
import { updateUserPreference } from '../../api/handlers/usePreferences';
import { AppsSectionContext } from '../SectionTabs/SectionTabs';

interface FavoriteAppContentProps {
  favoriteAppsList: FavoriteAppData[];
  setFavoriteAppsList: (data: FavoriteAppData[]) => void;
  isFavoriteSectionVisible: boolean;
}

export const FavoriteAppSectionContext = createContext<FavoriteAppContentProps>({} as FavoriteAppContentProps);

interface FavoriteAppSectionProps {
  data: CombinedData;
}
const FavoriteAppSection = ({ data }: FavoriteAppSectionProps): ReactElement => {
  const { t } = useTranslation();
  const [isFavoriteSectionVisible, setFavoriteSectionVisible] = React.useState(true);
  const [openToast, setOpenToast] = React.useState(false);
  const [toastMessage, setToastMessage] = React.useState(t('favoriteApps.save_message'));
  const { data: userPreferenceData, isFetching: isFetchingUserPreference } = useUserPreference();
  const [favoriteAppsList, setFavoriteAppsList] = React.useState<FavoriteAppData[]>([]);
  const sectionContext = useContext(AppsSectionContext);
  const toggleFavoriteSection = async (isFavoriteVisible: boolean) => {
    setFavoriteSectionVisible(isFavoriteVisible);
    await updateUserPreference({
      appFavorites: favoriteAppsList || [],
      sectionFavorites: sectionContext.sectionsList || [],
      showAppFavoritesSection: isFavoriteVisible,
    });
  };

  useEffect(() => {
    if (!isFetchingUserPreference) {
      // if it is undefined, then favorite section should be available by default
      setFavoriteSectionVisible(userPreferenceData?.showAppFavoritesSection ?? true);
      setFavoriteAppsList(formatFavoritesData(userPreferenceData?.appFavorites || [], data));
    }
  }, [userPreferenceData]);

  const onSaveFavoriteApps = () => {
    setToastMessage(t('favoriteApps.save_message'));
    setOpenToast(true);
  };

  const displayDiscardMessage = () => {
    setToastMessage(t('favoriteApps.discard_message'));
    setOpenToast(true);
  };
  return (
    <>
      <FavoriteAppSectionContext.Provider value={{ favoriteAppsList, setFavoriteAppsList, isFavoriteSectionVisible }}>
        <FavoriteSectionHeader>
          <FavoriteSectionHeaderCols data-testid="favorite-section-header">
            <StyledSectionTitle variant="h2">
              {isFavoriteSectionVisible && t('favoriteApps.section_title')}
            </StyledSectionTitle>
            <FavoriteAppMenu
              toggleFavoriteSection={toggleFavoriteSection}
              data={data}
              isFavoriteSectionVisible={isFavoriteSectionVisible}
              onSaveFavoriteApps={onSaveFavoriteApps}
              displayDiscardMessage={displayDiscardMessage}
            />
          </FavoriteSectionHeaderCols>
        </FavoriteSectionHeader>
        {isFavoriteSectionVisible && (
          <FavoritesAppContainer data-testid="favorites-app-container">
            {favoriteAppsList && favoriteAppsList.length > 0 ? (
              <PaginationSection FavoriteAppsData={favoriteAppsList} />
            ) : (
              <FavoriteEmptySection
                cardCount={3}
                footerSubtitle={t('favoriteApps.section_sub_title')}
              ></FavoriteEmptySection>
            )}
          </FavoritesAppContainer>
        )}
        <SnackBarToast
          open={openToast}
          onToastClose={() => setOpenToast(false)}
          message={toastMessage}
          vertical="top"
          horizontal="right"
        ></SnackBarToast>
      </FavoriteAppSectionContext.Provider>
    </>
  );
};

export default FavoriteAppSection;
