import { Box, styled, useTheme, FormControlLabel } from '@mui/material';

export const StyledSwitchContainer = styled(Box)(() => {
  const { tokens } = useTheme();
  return {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    gap: tokens.core.spacing['1_5'].value,
  };
});

export const SwitchLabel = styled('span')(() => {
  const { tokens } = useTheme();
  return {
    fontSize: '14px',
    lineHeight: tokens.core.spacing['2_5'].value,
  };
});

export const StyledFormControlLabel = styled(FormControlLabel)(() => {
  return {
    width: '100%',
  };
});

export const SwitchContainer = styled(Box)(() => {
  const { tokens } = useTheme();
  return {
    display: 'flex',
    width: '100%',
    alignItems: 'flex-start',
    flexDirection: 'column',
    gap: tokens.core.spacing['2'].value,
    alignSelf: 'stretch',
  };
});
