import { Typography, styled, useTheme, Box } from '@mui/material';

export const EmptyCardsSection = styled(Box)(() => {
  const { tokens } = useTheme();

  return {
    display: 'flex',
    alignItems: 'center',
    columnGap: tokens.core.spacing['2'].value,
    paddingBottom: tokens.core.spacing['1_5'].value,
    alignSelf: 'stretch',
    overflow: 'hidden',
  };
});

export const SkeletonSectionSubTitle = styled(Typography)(() => {
  const { tokens } = useTheme();
  const { semantic } = tokens;

  return {
    fontWeight: semantic.fontWeight.regular.value,
    fontSize: '14px',
    lineHeight: '20px !important',
  };
});
