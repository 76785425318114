import React from 'react';
import { QueryClientProvider } from '@tanstack/react-query';
import SPAWrapper from './components/SPAWrapper';
import queryClient from './queryClient';
import setupI18next from './helpers/setupI18next';
import AppContent from './components/AppContent';

setupI18next();

const App = () => {
  return (
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <SPAWrapper>
          <AppContent />
        </SPAWrapper>
      </QueryClientProvider>
    </React.StrictMode>
  );
};

export default App;
