import React, { FC, ReactElement, useState } from 'react';
import { Snackbar, Alert, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const UnderConstructionToast: FC = (): ReactElement => {
  const [open, setOpen] = useState(true);
  const { t } = useTranslation();

  const DismissButton = ({
    dismissClicked = () => {
      setOpen(false);
    },
  }) => (
    <Button variant="text" size="medium" onClick={dismissClicked}>
      Dismiss
    </Button>
  );

  function getActionButton() {
    return <DismissButton dismissClicked={() => setOpen(false)} />;
  }

  return (
    <>
      <Snackbar style={{ top: '33px' }} open={open} key={`${open}`}>
        <Alert severity="info" action={getActionButton()} variant={'standard'} aria-live="polite">
          {t('info_message.page_under_construction')}
        </Alert>
      </Snackbar>
    </>
  );
};

export default UnderConstructionToast;
