import React, { ReactElement } from 'react';
import { Switch } from '@mui/material';
import { StyledSwitchContainer, SwitchLabel, SwitchContainer, StyledFormControlLabel } from './SwitchComponent.style';
import { useTranslation } from 'react-i18next';

const SwitchComponent = (): ReactElement => {
  const [state, setCheckedState] = React.useState({
    homepage: false,
    sidebar: false,
  });
  const { t } = useTranslation();

  const handleCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedState({ ...state, [event.target.name]: event.target.checked });
  };

  return (
    <SwitchContainer>
      <StyledFormControlLabel
        control={
          <StyledSwitchContainer>
            <SwitchLabel>{t('favoriteApps.switch_label_home_page')}</SwitchLabel>
            <Switch
              checked={state.homepage}
              onChange={handleCheckChange}
              aria-label="Show favorites on the Home Page?"
              role="switch"
              name="homepage"
            />
          </StyledSwitchContainer>
        }
        label={state.homepage ? t('favoriteApps.switch_label_show') : t('favoriteApps.switch_label_hide')}
        labelPlacement="end"
      />
      <StyledFormControlLabel
        control={
          <StyledSwitchContainer>
            <SwitchLabel>{t('favoriteApps.switch_label_side_bar')}</SwitchLabel>
            <Switch
              checked={state.sidebar}
              onChange={handleCheckChange}
              aria-label="Show favorites on the Home Page?"
              role="switch"
              name="sidebar"
            />
          </StyledSwitchContainer>
        }
        label={state.sidebar ? t('favoriteApps.switch_label_show') : t('favoriteApps.switch_label_hide')}
        labelPlacement="end"
      />
    </SwitchContainer>
  );
};

export default SwitchComponent;
