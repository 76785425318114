import React from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import '@diligentcorp/atlas-web-component-icons/lib/drag-vertical/index.js';
import {
  CardContainer,
  CardIconDataContainer,
  IconContainer,
  StyledAppNameText,
} from '../FavoriteCard/FavoriteCard.style';
import AppSectionIcon from '../AppSectionIcon';
import { stripHtmlTags, replaceKey } from '../../helpers/common';
import { SectionCardProps } from '../../../types';

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'dil-drag-vertical-icon': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
    }
  }
}

const SectionCard: React.FC<SectionCardProps> = ({ sectionData }) => {
  const { t } = useTranslation();
  return (
    <CardContainer data-testid={`section-card-${sectionData.sectionKey}`} componentType="draggable">
      <CardIconDataContainer>
        <dil-drag-vertical-icon data-testid="drag-knob-icon"></dil-drag-vertical-icon>
        <IconContainer data-testid="section-icon">
          <AppSectionIcon sectionKey={sectionData?.sectionKey} size="lg" />
        </IconContainer>
        <Box data-testid="section-data">
          <StyledAppNameText>{stripHtmlTags(t(`sections.${replaceKey(sectionData.sectionKey!)}`))}</StyledAppNameText>
        </Box>
      </CardIconDataContainer>
    </CardContainer>
  );
};

export default SectionCard;
