//hardcoded tiles for boardsXP customers
const boardsCustomerApps = [
  {
    id: 1,
    title: 'Boards',
    url: '',
    key: 'boards',
  },
  {
    id: 2,
    title: 'Entities',
    url: '',
    key: 'entities',
  },
  {
    id: 3,
    title: 'Risk Manager',
    url: '',
    key: 'risk-manager',
  },
  {
    id: 4,
    title: 'Asset Manager',
    url: '',
    key: 'asset-manager',
  },
  {
    id: 5,
    title: 'Strategy',
    url: '',
    key: 'strategy',
  },
  {
    id: 6,
    title: 'Asset Inventory',
    url: '',
    key: 'asset-inventory',
  },
  {
    id: 7,
    title: 'Assessments',
    url: '',
    key: 'assessments',
  },
  {
    id: 8,
    title: 'Audit',
    url: '',
    key: 'audit',
  },
  {
    id: 9,
    title: 'Assurance Plans',
    url: '',
    key: 'assurance-plans',
  },
  {
    id: 10,
    title: 'Projects',
    url: '',
    key: 'projects',
  },
  {
    id: 11,
    title: 'Frameworks',
    url: '',
    key: 'frameworks',
  },
  {
    id: 12,
    title: 'Task Tracker',
    url: '',
    key: 'task-tracker',
  },
  {
    id: 13,
    title: 'Timesheets',
    url: '',
    key: 'project-timesheets',
  },
  {
    id: 14,
    title: 'Scheduler',
    url: '',
    key: 'project-scheduler',
  },
  {
    id: 15,
    title: 'Analytics',
    url: '',
    key: 'analytics',
  },
  {
    id: 16,
    title: 'Robots',
    url: '',
    key: 'robots',
  },
  {
    id: 17,
    title: 'Mission Control',
    url: '',
    key: 'mission-control',
  },
  {
    id: 18,
    title: 'ESG',
    url: '',
    key: 'accuvio',
  },
  {
    id: 19,
    title: 'Compliance Maps',
    url: '',
    key: 'compliance-maps',
  },
  {
    id: 20,
    title: 'Policy Manager',
    url: '',
    key: 'policy-manager',
  },
  {
    id: 21,
    title: 'DMI',
    url: '',
    key: 'dmi',
  },
  {
    id: 22,
    title: 'Activity Center',
    url: '',
    key: 'activity-center',
  },
  {
    id: 23,
    title: 'Results',
    url: '',
    key: 'results',
  },
  {
    id: 24,
    title: 'Storyboards',
    url: '',
    key: 'storyboards',
  },
  {
    id: 25,
    title: 'Issue Tracker',
    url: '',
    key: 'issue-tracker',
  },
  {
    id: 26,
    title: 'DMI',
    url: '',
    key: 'market-intelligence',
  },
];

export default boardsCustomerApps;
