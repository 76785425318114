import React from 'react';
import emptyStateSvgPath from 'assets/EmptyState.svg';
import { Link } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import Notification from './Notification';

const EmptyNotification = ({ supportLink }: { supportLink?: string }) => {
  const { t } = useTranslation();
  const _supportLink = supportLink || t('support_link');

  return (
    <Notification iconPath={emptyStateSvgPath} heading={t('empty_message.heading')}>
      <Trans
        i18nKey="empty_message.content"
        components={{ 'anchor-link': <Link target="_blank" rel="noopener noreferrer" href={_supportLink} />, p: <p /> }}
      />
    </Notification>
  );
};

export default EmptyNotification;
