import React, { createContext } from 'react';
import { Box, CircularProgress, useTheme } from '@mui/material';
import { getI18n, useTranslation } from 'react-i18next';
import { ErrorBoundary } from 'react-error-boundary';
import { useCombinedGlobalNavData, CombinedData } from '../../hooks/queries/useCombinedGlobalNavData';
import { Session } from '../../types/Session';
import { GlobalNav } from '../../types/GlobalNavData';
import ErrorNotification from '../Notification/ErrorNotification';
import EmptyNotification from '../Notification/EmptyNotification';
import { AppIcon } from '@diligentcorp/atlas-web-component-global-nav/lib/react';
import './SectionTabs.scss';
import { SpinnerWrapper, StyledTilesContainer, StyledCardContent, StyledSectionTitle } from './SectionTabs.styles';
import TabContent from '../TabContent';
import { replaceKey, sidebarSectionDataOnInitialRender, sortProcessedData } from '../../helpers/common';
import FavoriteAppSection from '../FavoriteAppSection';
import AppConfig from '../../appConfig';
import { SectionData } from '../../../types';
import useScreenSize from '../../hooks/useScreenSize';
import { useUserPreference } from '../../hooks/queries/useUserPreferences';
import { AppKey } from '@diligentcorp/atlas-web-component-global-nav/lib/configuration/types';

interface SectionContentProps {
  sectionsList: SectionData[];
  setSectionsList: (data: SectionData[]) => void;
  processedDatalist: CombinedData;
  setProcessedDatalist: (data: CombinedData) => void;
}

export const AppsSectionContext = createContext<SectionContentProps>({} as SectionContentProps);

function RenderTile({ title, appKey }: { title: string; appKey: AppKey }) {
  const { presets } = useTheme();
  const screenSize = useScreenSize();
  const { ButtonTilePresets } = presets;
  const { ButtonTile } = (ButtonTilePresets as { components: any })?.components;
  return (
    <ButtonTile
      size={screenSize < AppConfig.constants.mobileBreakpoint ? 'list' : 'large'}
      className="button-tile"
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        width: '100%',
      }}
      startIcon={<AppIcon appKey={appKey} />}
    >
      {title}
    </ButtonTile>
  );
}

function RenderSections({ data }: { data: CombinedData }) {
  const { t } = useTranslation();

  const processedData = data?.processedData;

  return (
    <>
      {AppConfig.featureToggles.useAppFavoritesFeature && (
        <Box>
          <FavoriteAppSection data={data} />
        </Box>
      )}
      {processedData &&
        processedData.map(({ apps, key: sectionKey }) => {
          if (apps.length === 0) return null;
          return (
            <Box id={sectionKey} key={sectionKey} data-testid={sectionKey}>
              <StyledSectionTitle variant="h2" data-testid={`${sectionKey}-title`}>
                <div
                  className="section-title"
                  dangerouslySetInnerHTML={{ __html: t(`sections.${replaceKey(sectionKey)}`) }}
                />
              </StyledSectionTitle>
              <StyledTilesContainer id={`${sectionKey}-content`} data-testid={`${sectionKey}-content`}>
                {apps.map((appPresentationData: any) => (
                  <StyledCardContent component="a" href={appPresentationData.url} key={appPresentationData.key}>
                    <RenderTile title={appPresentationData.title} appKey={appPresentationData.key} />
                  </StyledCardContent>
                ))}
              </StyledTilesContainer>
            </Box>
          );
        })}
    </>
  );
}

function SectionTabs({ session, globalNavData }: { session: Session; globalNavData: GlobalNav }) {
  React.useEffect(() => {
    const I18n = getI18n();
    I18n.changeLanguage(session.locale);
  }, [session.locale]);

  const [sectionsList, setSectionsList] = React.useState<SectionData[]>([]);
  const { data: userPreferenceData, isFetching: isFetchingUserPreference } = useUserPreference();
  const { data, isFetched: isPlatformComponentsFetched, isError, isLoading } = useCombinedGlobalNavData(globalNavData);
  const [processedDatalist, setProcessedDatalist] = React.useState<CombinedData>({} as CombinedData);
  const { presets } = useTheme();
  const { CircularProgressPresets } = presets;
  const supportLink = data?.globalNavData?.links?.support;

  React.useEffect(() => {
    if (!isFetchingUserPreference && isPlatformComponentsFetched) {
      if (data) {
        const processedData = userPreferenceData?.sectionFavorites
          ? sortProcessedData(userPreferenceData?.sectionFavorites || [], data)
          : data;
        setProcessedDatalist(processedData);

        const sectionData = sidebarSectionDataOnInitialRender(userPreferenceData?.sectionFavorites || [], data);
        setSectionsList(sectionData);
      }
    }
  }, [isFetchingUserPreference, isPlatformComponentsFetched, userPreferenceData]); // Add necessary dependencies

  if (isLoading || !isPlatformComponentsFetched || (data && !data.isGlobalNavDataFetched)) {
    return (
      <SpinnerWrapper>
        <CircularProgress variant="indeterminate" {...CircularProgressPresets.size.lg} />
      </SpinnerWrapper>
    );
  }

  if (isError) {
    return <ErrorNotification supportLink={supportLink} />;
  }

  if (!data || !data.hasAnyApp) {
    return <EmptyNotification supportLink={supportLink} />;
  }

  return (
    <ErrorBoundary fallback={<ErrorNotification />}>
      <AppsSectionContext.Provider value={{ sectionsList, setSectionsList, processedDatalist, setProcessedDatalist }}>
        <Box>
          {processedDatalist && processedDatalist.processedData && (
            <>
              <TabContent data={processedDatalist} />
              <RenderSections data={processedDatalist} />
            </>
          )}
        </Box>
      </AppsSectionContext.Provider>
    </ErrorBoundary>
  );
}

export default SectionTabs;
