import React from 'react';
import { Box, CircularProgress, useTheme } from '@mui/material';
import { getI18n, useTranslation } from 'react-i18next';
import { ErrorBoundary } from 'react-error-boundary';
import { useCombinedGlobalNavData, CombinedData } from '../../hooks/queries/useCombinedGlobalNavData';
import { Session } from '../../types/Session';
import { GlobalNav } from '../../types/GlobalNavData';
import ErrorNotification from '../Notification/ErrorNotification';
import EmptyNotification from '../Notification/EmptyNotification';
import { AppIcon } from '@diligentcorp/atlas-web-component-global-nav/lib/react';
import ExternalLink from '@diligentcorp/atlas-react-icons/dist/esm/lens/ExternalLink.js';
// added .scss file to apply border color as button tile is not having override prop now.
// This will be updated once button tiles is updated to pass sx prop

import DemoContent from '../DemoContent';

import {
  SpinnerWrapper,
  StyledTilesContainer,
  StyledCardContent,
  StyledSectionTitle,
  StyledBoxTiTle,
} from './BoardSectionTabs.styles';
import TabContent from '../TabContent';
import { getTileType, replaceKey } from '../../helpers/common';

interface AppIconWrapperProps {
  appKey: any;
}

const AppIconWrapper: React.FC<AppIconWrapperProps> = ({ ...props }) => {
  return <AppIcon {...props} />;
};

function RenderTile({ title, key, url }: any) {
  const [open, setOpen] = React.useState(false);

  const { presets } = useTheme();
  const { ButtonTilePresets } = presets;
  const { ButtonTile } = (ButtonTilePresets as { components: any })?.components;
  const tiletype: string = getTileType(key);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  switch (tiletype) {
    case 'activeApp':
      return (
        <StyledCardContent component="a" href={url} className="boardSectionTile">
          <ButtonTile sx={{ border: '1px solid #dadada' }} size="large" startIcon={<AppIconWrapper appKey={key} />}>
            <StyledBoxTiTle>
              {title} <ExternalLink />
            </StyledBoxTiTle>
          </ButtonTile>
        </StyledCardContent>
      );
    case 'demoApp':
      return (
        <>
          <StyledCardContent onClick={handleClickOpen} component="a" className="boardSectionTile">
            <ButtonTile
              sx={{ border: '1px solid #dadada' }}
              muted
              size="large"
              startIcon={<AppIconWrapper appKey={key} />}
            >
              <StyledBoxTiTle>
                {title} <ExternalLink />
              </StyledBoxTiTle>
            </ButtonTile>
          </StyledCardContent>
          <DemoContent open={open} onClose={handleClose} title={title} appKey={key} />
        </>
      );
    case 'mutedApp':
      return (
        <StyledCardContent component="a" className="boardSectionTile">
          <ButtonTile
            sx={{ border: '1px solid #dadada' }}
            muted
            size="large"
            startIcon={<AppIconWrapper appKey={key} />}
          >
            <StyledBoxTiTle> {title}</StyledBoxTiTle>
          </ButtonTile>
        </StyledCardContent>
      );
  }
}

function RenderSections({ data }: { data: CombinedData }) {
  const { t } = useTranslation();
  const { processedData } = data;

  return (
    <>
      {processedData.map(({ apps, key: sectionKey }) => {
        if (apps.length === 0) return null;
        return (
          <Box id={sectionKey}>
            <StyledSectionTitle variant="h2">
              {
                <div
                  className="section-title"
                  dangerouslySetInnerHTML={{ __html: t(`sections.${replaceKey(sectionKey)}`) }}
                />
              }
            </StyledSectionTitle>
            <StyledTilesContainer id={`${sectionKey}-content`}>
              {apps.map((appPresentationData: any) => (
                <>{RenderTile(appPresentationData)}</>
              ))}
            </StyledTilesContainer>
          </Box>
        );
      })}
    </>
  );
}

function BoardSectionTabs({ session, globalNavData }: { session: Session; globalNavData: GlobalNav }) {
  React.useEffect(() => {
    const I18n = getI18n();
    I18n.changeLanguage(session.locale);
  }, [session.locale]);

  const { data, isFetched: isPlatformComponentsFetched, isError, isLoading } = useCombinedGlobalNavData(globalNavData);
  const { presets } = useTheme();
  const { CircularProgressPresets } = presets;
  const supportLink = data?.globalNavData?.links?.support;

  if (isLoading || !isPlatformComponentsFetched || (data && !data.isGlobalNavDataFetched)) {
    return (
      <SpinnerWrapper>
        <CircularProgress variant="indeterminate" {...CircularProgressPresets.size.lg} />
      </SpinnerWrapper>
    );
  }

  if (isError) {
    return <ErrorNotification supportLink={supportLink} />;
  }

  if (!data || !data.hasAnyApp) {
    return <EmptyNotification supportLink={supportLink} />;
  }

  return (
    <ErrorBoundary fallback={<ErrorNotification />}>
      <Box>
        <TabContent data={data} />
        <RenderSections data={data} />
      </Box>
    </ErrorBoundary>
  );
}

export default BoardSectionTabs;
