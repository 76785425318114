import React from 'react';
import errorStateSvgPath from 'assets/ErrorState.svg';
import { Link } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import Notification from './Notification';

const ErrorNotification = ({ supportLink }: { supportLink?: string }) => {
  const { t } = useTranslation();
  const _supportLink = supportLink || t('support_link');

  return (
    <Notification iconPath={errorStateSvgPath} heading={t('error_message.heading')}>
      <Trans
        i18nKey="error_message.content"
        components={{ 'anchor-link': <Link target="_blank" rel="noopener noreferrer" href={_supportLink} />, p: <p /> }}
      />
    </Notification>
  );
};

export default ErrorNotification;
