import React from 'react';
import { GlobalStyles, useTheme } from '@mui/material';
import GlobalNavigator from '../GlobalNav/GlobalNav';
import { useSession } from '../../hooks/queries/useSession';
import { getGlobalStyles } from './AppContent.styles';
import AppConfig from '../../appConfig';
import addScript from '../../helpers/addScript';
import LandingPageContent from '../LandingPageContent';
import '@diligentcorp/atlas-design-tokens/lens.css';

function AppContent() {
  const { data: session } = useSession();
  const [monitorScriptLoaded, setMonitorScriptLoaded] = React.useState(false);
  const { tokens } = useTheme();

  React.useEffect(() => {
    if (!monitorScriptLoaded && session && !AppConfig.environment.isGovRegion) {
      addScript(AppConfig.pendoMonitorScript!, false, true);
      setMonitorScriptLoaded(true);
    }
  }, [session]);

  if (!session) {
    return null;
  }

  return (
    <>
      <GlobalStyles styles={getGlobalStyles(tokens.semantic.color.transparent.default.value)} />
      <GlobalNavigator />
      <main data-atlas-gn-app>
        <LandingPageContent session={session} />
      </main>
    </>
  );
}

export default AppContent;
