import React from 'react';
import { Tabs, Tab } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { StyledTabsContainer } from './TabContent.styles';
import { CombinedData } from '../../hooks/queries/useCombinedGlobalNavData';
import { SectionKey } from '@diligentcorp/atlas-web-component-global-nav/lib/configuration/types';

const TabContent = ({ data }: { data: CombinedData }) => {
  const { t } = useTranslation();
  const [value, setValue] = React.useState(0);

  const { processedData } = data;

  const handleChange = (_event: React.ChangeEvent<unknown>, newValue: any) => {
    setValue(newValue);
  };
  function replaceKey(key: SectionKey) {
    return key.replace(/[_-]/g, '-');
  }

  return (
    <StyledTabsContainer>
      <Tabs
        value={value}
        onChange={handleChange}
        className=""
        aria-label="simple tabs example"
        variant="scrollable"
        scrollButtons="auto"
        allowScrollButtonsMobile={true}
        data-testid="tab-widget"
      >
        {processedData &&
          processedData.map(({ apps, key: sectionKey }) => {
            if (apps.length === 0) return null;

            return (
              <Tab
                key={`tab-${sectionKey}`}
                label={<div dangerouslySetInnerHTML={{ __html: t(`sections.${replaceKey(sectionKey)}`) }} />}
                id={`tab-widget-${sectionKey}`}
                aria-controls={`tab-widget-panel-${sectionKey}`}
                icon={undefined}
                href={'#' + sectionKey}
                target="_self"
                data-testid={`tab-widget-${sectionKey}`}
              />
            );
          })}
      </Tabs>
    </StyledTabsContainer>
  );
};

export default TabContent;
