import React from 'react';
import { AtlasThemeProvider } from '@diligentcorp/atlas-theme-mui';
import { lensThemeOptions } from '@diligentcorp/atlas-theme-mui/lib/themes/lens/index.js';
import { useSession } from '../../hooks/queries/useSession';
import { muiLocales } from '../../locales/muiLocales';
import { FALLBACK_LNG } from '../../locales';

function SPAWrapper({ children }: { children: React.ReactNode }) {
  const { data, isFetching } = useSession();

  if (isFetching) {
    return null;
  }

  return (
    <AtlasThemeProvider themeOptions={lensThemeOptions} locales={muiLocales[data?.locale || FALLBACK_LNG]}>
      {children}
    </AtlasThemeProvider>
  );
}

export default SPAWrapper;
